// @flow
import React, { useState, useEffect, useCallback, useRef, memo } from "react"
import { navigate } from "gatsby"
import { RichText } from "prismic-reactjs"
import { renderToString } from "react-dom/server"

import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"
import type { CreateAccountPageType } from "../../types/CreateAccountPageType"
import { API, graphqlOperation } from "aws-amplify"

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import {
  Spinner,
  Button,
  // Textbox,
  // FormValidator,
  // checkHaveErrors,
  ROUTES,
  getLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  convertCentsToEuro,
  // Tooltip,
  TrialPopover,
  formatDateTime,
  getTrialEndDate,
  handleErrorWithPrismic,
  deleteLocalStorage,
  CREATE_ACCOUNT_STATUS,
  getCreateAccountRoute,
  formatIntervalUnit,
  // captureException,
  SENTRY_ACTIONS,
  GetUserPayment,
  linkResolver,
  getCurrentUser,
  getSubscriptionType,
  scrollToTop,
  DATETIME_FORMAT,
  getPaymentMethodType,
  CURRENCIES,
  allowRolloverPreferences,
  getSessionStorage,
  CURRENT_COUNTRY,
  USER_COUNTRY,
  SKIP_3DS,
  getIncTaxText,
  ROLLOVER_PREFERENCE,
  setLocalStorage,
  isEmpty,
  getAutomationRecaptcha,
  handleVerifyRecaptchaV2,
  handleFailedAPIWithRecaptcha,
  handleClickOutSideRecaptcha,
} from "@lesmills/gatsby-theme-common"

import { enabled3DSBillingAddress } from "../../utils/payment"
import CreateAccountContainer from "./CreateAccountContainer"
import PaymentMethod from "../PaymentMethod"
import InformationRow from "../InformationRow"
import InformationWrapper from "../InformationWrapper"
import SelectSubscriptionModal from "../SelectSubscriptionModal"
import { Link } from "gatsby"
// import { UpdateUser } from "../../graphql/updateUser"
// import Popup from "../Popup"
import UpdateLabel from "../UpdateLabel"
import { newSubscriptionByChargifyTokenV2 } from "../../graphql/newSubscriptionByChargifyTokenV2"
import { newSubscriptionWithPromotion } from "../../graphql/newSubscriptionWithPromotion"
import { createAffiliateSubscriptionByChargifyTokenV2 } from "../../graphql/createAffiliateSubscriptionByChargifyTokenV2"
import { formalizeRolloverLabel, ROLLOVER_OPTION } from "./RolloverPreferences"
import RolloverPreferencesModal from "./RolloverPreferencesModal"
import loadable from "@loadable/component"
import useGetRolloverCountries from "../MyAccount/hooks/useGetRolloverCountries"

const ReCAPTCHA = loadable(() => import("react-google-recaptcha"), {
  fallback: <Spinner />,
})

const NotificationToast = loadable(
  () => import("@lesmills/gatsby-theme-common"),
  {
    resolveComponent: components => components.NotificationToast,
  }
)

type Props = {|
  data: CreateAccountPageType,
  lang: string,
  isAffiliate: Boolean,
  layoutData?: AllLayoutsType,
  navigateLocation?: Object,
  user: UserType,
  isCanceledAccount: Boolean,
  isAllowRollover: Boolean,
  afterSelectSubscription: () => void | Promise<void>,
  setRolloverOption: () => void | Promise<void>,
  rolloverOption: string,
  isShowSelectedSubBanner: Boolean,
  setIsShowSelectedSubBanner: () => void,
  selectedSubscription: Object,
  isNotIncludedTax?: Boolean,
  promotionData: Object,
|}

const Summary = ({
  data = {},
  lang,
  isAffiliate,
  isCanceledAccount,
  layoutData = {},
  navigateLocation = {},
  user = {},
  isAllowRollover = false,
  afterSelectSubscription = () => {},
  rolloverOption = "",
  setRolloverOption = () => {},
  isShowSelectedSubBanner,
  setIsShowSelectedSubBanner,
  selectedSubscription,
  isNotIncludedTax,
  promotionData,
}: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const state = navigateLocation.state || {}
  const savedRollover =
    typeof getLocalStorage(ROLLOVER_PREFERENCE) === "string"
      ? getLocalStorage(ROLLOVER_PREFERENCE)
      : ""
  const [paymentMethod, setPayment] = useState(state.paymentMethod)
  const [showRolloverPreferences, setShowRolloverPreferences] = useState(false)
  const isRolloverOption =
    (rolloverOption ? rolloverOption : savedRollover) ===
    ROLLOVER_OPTION.rollback

  const [isProcessing, setIsProcessing] = useState(false)

  // This state is used to check whether showing loading message or not
  const [isShowLoadingMessage, setShowLoadingMessage] = useState(false)
  const [isOpenChangeSubscriptionType, toggleChangeSubscriptionType] = useState(
    false
  )
  // const [isOpenChangeEmail, toggleChangeEmail] = useState(false)
  // const [changedEmail, setChangedEmail] = useState("")
  // const emailRef = useRef(null)
  const [errors, setErrors] = useState({})
  const [responseStatus, setResponseStatus] = useState({})
  const recaptchaAutomation = getAutomationRecaptcha()
  let reCaptchaRef = useRef()

  const currentSubscription = getLocalStorage(NEW_SUBSCRIPTION_KEY)
  const {
    name,
    product_price_point = {},
    offerId,
    product_name,
    affiliateId,
    transactionId,
    currency,
    offer_handle,
    promoCode,
    signedLink,
  } = currentSubscription
  const {
    price_in_cents,
    trial_interval,
    trial_interval_unit,
    trial_price_in_cents,
    interval,
    interval_unit,
    final_price_in_cents,
  } = product_price_point || {}
  const { plus_tax_label = [] } = layoutData
  const plusTaxText = plus_tax_label.text
  const currencySymbol = CURRENCIES[currency]
  /**
   * Should display the official price of the sub that user has to pay.
   * For annual sub → /year; Monthly sub → /month; Daily sub → /day
   * If Monthly Sub has no trial and its price is $5, then display $5/month
   * If Annual Sub has a trial and its price is $30, then display $30/year
   */
  const price = convertCentsToEuro(
    final_price_in_cents || price_in_cents,
    currency,
    2
  )
  const selectedSubscriptionTitle = `${
    isAffiliate ? product_name : name
  } (${currencySymbol}${price}/${formatIntervalUnit(
    { interval_unit, interval },
    layoutData
    // https://lesmillsinternational.atlassian.net/browse/LA-1221
    // The price of subscription in US and CA is not included tax
    // Add "plus Tax" right after price in US and CA
  )}${isNotIncludedTax ? `, ${plusTaxText}` : ""})`
  /**
   * Should be the amount that the user has to pay right after the selected sub is created successfully instead.
   * Sub has no trial, price = $5 ⇒ Amount due today = $5
   * Sub has trial with trial fee = $1, sub's price = $5 ⇒ Amount due today = $1 (and when the next billing date comes, user will be pay $5)
   * Sub has trial with trial fee = $0, sub's price = $5 ⇒ Amount due today = $0 (and when the next billing date comes, user will be pay $5)
   */
  const amountDue =
    !isCanceledAccount && trial_interval
      ? convertCentsToEuro(trial_price_in_cents, currency, 2)
      : price

  const {
    summary_subtitle = {},
    summary_title = {},
    confirm_button_label = {},
    confirm_loading_button_label = {},
    free_trial_note = {},
    body = [],
    body1 = [],
    select_subscription_title = {},
    // double_check_email_reminder = {},
    // email_label = {},
    // email_required = {},
    // email_invalid = {},
    // change_email_title = {},
    // change_email_cancel_button_label = {},
    // change_email_save_button_label = {},
    with_trial_label = {},
    // change_email_error = {},
    // same_email_error = {},
    payment_note_with_trial = {},
    payment_note_without_trial = {},
    lmod_cns_err = {},
    start_now_button = {},
    selected_button = {},
    incorrect_email_title = {},
    incorrect_email_message = {},
    creating_subscription_message = {},
    rollover_preferences_title = {},
    change_rollover_preferences = {},
    rollover_preferences_rollback_label = {},
    rollover_preferences_continue_label = {},
  } = data || {}
  const defaultConfirmButtonText = confirm_button_label.text
  let countryButtonLabel = confirm_button_label
  const countryCode = (
    getSessionStorage(CURRENT_COUNTRY) || getSessionStorage(USER_COUNTRY)
  ).toLowerCase()
  const isUS = countryCode === "us"
  const isCountryButtonExist = () => {
    countryButtonLabel = data[`${countryCode}_confirm_button_label`]

    return !!countryButtonLabel && !isEmpty(countryButtonLabel)
  }
  // https://lesmillsinternational.atlassian.net/browse/LA-1385
  const getConfirmButtonLabel = () => {
    if (isCountryButtonExist()) {
      return countryButtonLabel.text
    }
    return defaultConfirmButtonText
  }

  // Generate recaptcha token V3
  const verifyRecaptchaV3 = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    const recaptchaV3Token = await executeRecaptcha()
    // Handle with returned token
    handleCreateSubscriptionByChargifyToken(recaptchaV3Token)
  }, [executeRecaptcha])

  useEffect(() => {
    // Push data layer event
    // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-952

    // if not existing payment in state of navigate => getUserPayment
    if (!paymentMethod) {
      getCurrentUser(
        res => {
          // Set payment method
          setPayment(res.chargifyPaymentProfile || res.paymentMethod)
        },
        undefined,
        undefined,
        undefined,
        undefined,
        GetUserPayment
      )
    }
  }, [])

  const { rolloverCountries } = useGetRolloverCountries()

  useEffect(() => {
    if (isProcessing) {
      // Delay 3s before showing loading message
      // Jira ticket: https://lesmillsinternational.atlassian.net/browse/LA-795
      setTimeout(() => {
        setShowLoadingMessage(true)
      }, 3000)
    } else {
      setShowLoadingMessage(false)
    }
  }, [isProcessing])

  const handleCreateSubscriptionErrors = async errorsAPI => {
    await handleFailedAPIWithRecaptcha(
      errorsAPI,
      () => {
        handleVerifyRecaptchaV2(reCaptchaRef, tokenV2 => {
          handleCreateSubscriptionByChargifyToken(tokenV2, true)
        })
      },
      () => {
        handleCardErrors(
          clarifyErrorType(errorsAPI ? errorsAPI : [errorsAPI]),
          isAffiliate
            ? SENTRY_ACTIONS.CREATE_AFFILIATE_SUBSCRIPTION
            : SENTRY_ACTIONS.CREATE_SUBSCRIPTION
        )
        // Only turn off indicator in case errors
        setIsProcessing(false)
        return
      }
    )
  }

  const setRecaptchaAutomationParams = input => {
    input.captcha_signature = {
      auth: recaptchaAutomation.auth || "",
      nonce: recaptchaAutomation.nonce || "",
    }
    input.recaptcha_token = "" // recaptcha_token is required so need pass as empty string when automation test
  }

  const setNonRecaptchaAutomationParams = (
    inputParams,
    isRecaptchaV2,
    recaptchaToken
  ) => {
    inputParams.recaptcha_token = isRecaptchaV2 ? recaptchaToken : ""
    inputParams.recaptcha_token_V3 = isRecaptchaV2 ? "" : recaptchaToken
  }

  const handleCreateSubscriptionByChargifyToken = async (
    recaptchaToken = null,
    isRecaptchaV2 = false
  ) => {
    try {
      let response
      const isSkip3DS = getLocalStorage(SKIP_3DS)
      if (isAffiliate || !!offer_handle) {
        // LA-1810: also call createAffiliateSubscription with promotion offer
        let requestAffiliateData = {
          offerId,
          affiliateId,
          transactionId,
          chargifyOfferHandle: offer_handle,
        }

        if (isAllowRollover) {
          requestAffiliateData.defaultToMonthly = rolloverOption
            ? rolloverOption
            : savedRollover === ROLLOVER_OPTION.rollback
        }

        if (recaptchaAutomation) {
          setRecaptchaAutomationParams(requestAffiliateData)
        } else {
          setNonRecaptchaAutomationParams(
            requestAffiliateData,
            isRecaptchaV2,
            recaptchaToken
          )
        }
        response = await API.graphql(
          graphqlOperation(
            createAffiliateSubscriptionByChargifyTokenV2,
            requestAffiliateData
          )
        )
      } else {
        let requestData = {}

        if (recaptchaAutomation) {
          setRecaptchaAutomationParams(requestData)
        } else {
          setNonRecaptchaAutomationParams(
            requestData,
            isRecaptchaV2,
            recaptchaToken
          )
        }

        if (isAllowRollover) {
          requestData = {
            ...requestData,
            default_to_monthly: rolloverOption
              ? rolloverOption
              : savedRollover === ROLLOVER_OPTION.rollback,
          }
        }

        if (enabled3DSBillingAddress(countryCode) && isSkip3DS === true) {
          requestData.skip3ds = true
        }
        // Fix new product_handle is not updated
        const newCurrentSubscription = getLocalStorage(NEW_SUBSCRIPTION_KEY)
        const {
          product_handle,
          signedLink,
          pricePointId,
        } = newCurrentSubscription

        requestData = {
          ...requestData,
          product_handle,
          ...(pricePointId && {
            price_point_id: pricePointId,
          }),
        }

        const guidSignedLink = signedLink && signedLink.guid
        if (guidSignedLink) {
          requestData = {
            ...requestData,
            promotion_signed_link_id: guidSignedLink,
          }
        }
        response = await API.graphql(
          graphqlOperation(
            guidSignedLink
              ? newSubscriptionWithPromotion
              : newSubscriptionByChargifyTokenV2,
            requestData
          )
        )
      }
      const errorsResponse = response.errors
      // Handle error from response
      if (errorsResponse && errorsResponse.length > 0) {
        handleCreateSubscriptionErrors(errorsResponse)
      }

      // reset seleted subscription after finished create account
      deleteLocalStorage(CREATE_ACCOUNT_STATUS)
      deleteLocalStorage(NEW_SUBSCRIPTION_KEY)

      // reset SKIP_3DS after finished create subs
      deleteLocalStorage(SKIP_3DS)

      // reset rollover preference after finishing create subs
      deleteLocalStorage(ROLLOVER_PREFERENCE)

      // Redirect to Get Started page
      navigate(ROUTES(lang).GETTING_STARTED, {
        state: {
          fromCreateAccount: true,
          promoCode,
          paymentMethod: getPaymentMethodType(paymentMethod),
          isAffiliate,
        },
      })
    } catch (err) {
      const error = err && err.errors
      handleCreateSubscriptionErrors(error)
    }
  }

  // Clarify only two errors when creating subscription
  // One is existing subscription and default is fail on creating subscription
  const clarifyErrorType = (responseErrors = []) => {
    return responseErrors.map(error =>
      error.errorType === "lmod_dcsia_err" ||
      error.errorType === "lmod_vpsl_err"
        ? error
        : {
            ...error,
            errorType: "lmod_cns_err",
          }
    )
  }

  const handleCardErrors = (err, action) => {
    handleErrorWithPrismic(
      err,
      lmod_cns_err.text,
      setErrors,
      data,
      action,
      true // Only save prismic data to use in Rich text instead of message
    )
    scrollToTop()
  }

  const onClickOutsideRecaptcha = () => {
    setIsProcessing(false)
  }

  const handleSubmit = () => {
    // form validator
    setIsProcessing(true)
    if (recaptchaAutomation) {
      handleCreateSubscriptionByChargifyToken()
    } else {
      // Generate and verify API with recaptcha V3
      verifyRecaptchaV3()
      handleClickOutSideRecaptcha(onClickOutsideRecaptcha)
    }
  }

  const handleDisableLink = e => {
    if (isProcessing) {
      e.preventDefault()
    }
    return
  }

  const faq = body[0] || {}
  const questions = faq.items || []
  const { faq_title = {} } = faq.primary || {}
  const { email, givenName } = user
  const cart_summary = body1[0] || {}
  const subscription_term = body1[1] || {}
  const coachTerm = body1[2] || {}
  const subscriptionTermPrimary = subscription_term.primary || {}
  const subscriptionTermItems = subscription_term.items || []
  const cartSummaryPrimary = cart_summary.primary || {}
  const { cart_summary_title = {} } = cartSummaryPrimary
  const [
    accountEmailField = {},
    selectedSubscriptionField = {
      name: {},
      update_button_label: {},
    },
    amountField = {
      name: {},
    },
    paymentMethodField = {
      name: {},
      update_button_label: {},
    },
  ] = cart_summary.items || []

  const { subscription_term_title = {} } = subscriptionTermPrimary

  // Change email function was disabled on Summary step. It's only available in update personal info
  // Handle change email popup
  // const getValidatorErrors = emailAddress => ({
  //   email: FormValidator(["REQUIRED", "EMAIL"], emailAddress, {
  //     requiredError: getPrismicText(email_required),
  //     invalidError: getPrismicText(email_invalid),
  //   }),
  // })

  // const changeEmail = async (currentUser, newEmailAddress) => {
  //   setIsProcessing(true)
  //   try {
  //     const response = await API.graphql(
  //       graphqlOperation(UpdateUser, {
  //         input: {
  //           givenName: currentUser.given_name,
  //           familyName: currentUser.family_name,
  //           email: newEmailAddress,
  //         },
  //       })
  //     )
  //     setIsProcessing(false)

  //     if (response.errors && response.errors.length > 0) {
  //       handleErrorWithPrismic(
  //         response.errors,
  //         getPrismicText(change_email_error),
  //         setResponseStatus,
  //         data,
  //         SENTRY_ACTIONS.UPDATE_USER
  //       )
  //     } else {
  //       setChangedEmail(newEmailAddress)
  //       toggleChangeEmail(false)
  //     }
  //   } catch (err) {
  //     setIsProcessing(false)
  //     setResponseStatus({
  //       message: getPrismicText(change_email_error),
  //       type: "error",
  //     })
  //   }
  // }

  // const handleChangeEmail = () => {
  //   const newEmail = emailRef.current && emailRef.current.value.trim()
  //   let emailErrors = {}

  //   if (
  //     (changedEmail.length === 0 && newEmail === user.email) ||
  //     (changedEmail.length > 0 && newEmail === changedEmail)
  //   ) {
  //     emailErrors = { email: getPrismicText(same_email_error) }
  //   } else {
  //     emailErrors = getValidatorErrors(newEmail)
  //   }

  //   setErrors({
  //     ...emailErrors,
  //   })

  //   // Valid email
  //   if (!checkHaveErrors(emailErrors)) {
  //     // call change email api
  //     changeEmail(user, newEmail)
  //   }
  // }

  // const handleOnKeyPress = (e, submitForm) => {
  //   if (e.which === 13) {
  //     e.preventDefault()
  //     submitForm()
  //   }
  // }

  const renderTerms = () => {
    return (
      <>
        <div className="mt-30" data-cy="subscription-term">
          <h3 className="font-base-black text-base leading-4none md:text-2lg md:leading-none text-gray-400 uppercase">
            {subscription_term_title.text}
          </h3>
          <div
            className={`flex flex-col pb-20 text-base font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal text-3xs ${
              isUS ? "" : "border-b-2 border-gray-200"
            }`}
          >
            {subscriptionTermItems.map((item, index) => (
              <div className="mt-15" key={index + "-" + "term"}>
                {item.description.text.includes("@interval") ? (
                  <p>
                    {item.description.text.replace(
                      "@interval",
                      getSubscriptionType(
                        product_price_point,
                        layoutData,
                        true
                      ).toLowerCase()
                    )}
                  </p>
                ) : isCountryButtonExist() &&
                  item.description.text.includes(defaultConfirmButtonText) ? (
                  <div
                    // Convert html render description to string
                    // Replace default button label by current country button label
                    // And reset inner html after replacing
                    dangerouslySetInnerHTML={{
                      __html: renderToString(
                        <RichText render={item.description.raw} />
                      ).replace(
                        defaultConfirmButtonText,
                        countryButtonLabel.text
                      ),
                    }}
                  />
                ) : (
                  <RichText render={item.description.raw} />
                )}
              </div>
            ))}
          </div>
        </div>
        {isUS && (
          <div className="mt-30">
            <h3 className="font-base-black text-base leading-4none md:text-2lg md:leading-none text-gray-400 uppercase">
              {coachTerm.primary.subscription_term_title.text}
            </h3>
            <div className="flex flex-col border-b-2 border-gray-200 pb-20 text-base font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal text-3xs">
              {coachTerm.items.map((item, index) => (
                <div className="mt-15" key={index + "-" + "coach_term"}>
                  <RichText render={item.description.raw} />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }

  const renderPaymentNoteWithTrial = () => (
    <>
      {payment_note_with_trial.raw.map((note, index) => (
        <p className="mt-5" key={index + "-" + "note"}>
          {note.text
            .replace(
              "@billing_date",
              formatDateTime(
                getTrialEndDate(trial_interval, trial_interval_unit),
                DATETIME_FORMAT.default,
                layoutData
              )
            )
            .replace(
              "@price",
              `${currencySymbol}${price}${
                // https://lesmillsinternational.atlassian.net/browse/LA-1221
                // The price of subscription in US and CA is not included tax
                // Add "plus Tax" right after price in US and CA
                isNotIncludedTax ? `(${plusTaxText})` : ""
              }`
            )
            .replace("@subscription", isAffiliate ? product_name : name)}
        </p>
      ))}
    </>
  )

  const renderPaymentNotes = () => (
    <div className="font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal text-3xs border-b-2 border-gray-200 pb-35 mt-30">
      {!isCanceledAccount ? ( // https://lesmillsinternational.atlassian.net/browse/LA-2067: Hide payment note to old users when they re-start their subscription
        <>
          {trial_interval && user?.isEntitledToFreeTrial ? (
            renderPaymentNoteWithTrial()
          ) : (
            <p className="mt-5">
              {payment_note_without_trial.text &&
                payment_note_without_trial.text
                  .replace(
                    "@price",
                    `${currencySymbol}${price}${
                      // https://lesmillsinternational.atlassian.net/browse/LA-1221
                      // The price of subscription in US and CA is not included tax
                      // Add "plus Tax" right after price in US and CA
                      isNotIncludedTax ? `(${plusTaxText})` : ""
                    }`
                  )
                  .replace("@subscription", isAffiliate ? product_name : name)}
            </p>
          )}
        </>
      ) : null}
    </div>
  )

  const renderRolloverPreferences = () =>
    isAllowRollover && (
      <div className="flex justify-between items-baseline">
        <InformationRow
          label={rollover_preferences_title.text}
          value={
            formalizeRolloverLabel(
              isRolloverOption
                ? rollover_preferences_rollback_label
                : rollover_preferences_continue_label,
              currentSubscription,
              selectedSubscription
            ).text
          }
          testId="rollover-preferences"
        />
        <Link
          className={`underline text-3xs leading-2normal md:w-250 md:text-2lg md:text-right font-base-light md:leading-7none text-gray-800${
            isProcessing ? " disabled" : ""
          }`}
          data-cy="update-rollover-option-btn"
          to={getCreateAccountRoute(isAffiliate, lang) + "?step=payment"}
          onClick={handleDisableLink}
        >
          <UpdateLabel label={change_rollover_preferences.text} />
        </Link>

        {showRolloverPreferences && (
          <RolloverPreferencesModal
            layoutData={layoutData}
            currentSubscription={currentSubscription}
            selectedSubscription={selectedSubscription}
            onDismiss={() => setShowRolloverPreferences(false)}
            onSaveRolloverPreferences={handleSaveRolloverPreferences}
            prismicData={data}
          />
        )}
      </div>
    )

  const handleSaveRolloverPreferences = option => {
    setShowRolloverPreferences(false)
    setRolloverOption(option)
    setLocalStorage(ROLLOVER_PREFERENCE, option)
  }

  const callbackAfterChangeSubscription = newSubscription => {
    afterSelectSubscription(newSubscription)

    if (
      allowRolloverPreferences({
        subscription: newSubscription,
        rolloverCountries,
      })
    ) {
      // Show rollover preferences popup
      setShowRolloverPreferences(true)
    }
  }

  return (
    <CreateAccountContainer
      title={`${summary_title.text}${givenName ? `, ${givenName}` : ""}?`}
      subtitle={summary_subtitle}
      testId="summary-step-container"
      classNames={{ wrapper: "animation-face-in-right" }}
    >
      {Object.keys(errors).length > 0 && (
        <NotificationToast
          children={
            <RichText render={errors.message.raw} linkResolver={linkResolver} />
          }
          showHideIcon
          handleHideToast={() => setErrors({})}
          type="error"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      {Object.keys(responseStatus).length > 0 && (
        <NotificationToast
          children={<p>{responseStatus.message}</p>}
          showHideIcon
          handleHideToast={() => setResponseStatus({})}
          type={"error"}
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      {isShowSelectedSubBanner && (
        <NotificationToast
          children={
            <p>
              {selectedSubscription.name} {selected_button.text}
            </p>
          }
          showHideIcon
          handleHideToast={() => setIsShowSelectedSubBanner(false)}
          type="success"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      <InformationWrapper
        title={cart_summary_title.text}
        classNames={{ wrapper: " md:mt-41 mt-20", title: " mt-0" }}
      >
        {user && email && (
          <div className="flex justify-between items-baseline">
            <div className="md:mt-10 mt-15">
              {/*<Tooltip
                label={changedEmail.length > 0 ? changedEmail : email}
                content={getPrismicText(double_check_email_reminder)}
                direction="left"
                classNames={{
                  label:
                    " font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal tex-base",
                  content:
                    " animation-fade-out-slow animation-delay-5 animation-fill-mode-forwards font-base-black md:text-2lg",
                }}
              /> */}
              <InformationRow
                label={
                  <TrialPopover
                    label={accountEmailField.name.text}
                    title={incorrect_email_title.text}
                    description={
                      <RichText render={incorrect_email_message.raw} />
                    }
                    classNames={{
                      label:
                        "mb-3 font-base-black text-base leading-4none md:text-2lg md:leading-none text-gray-400",
                      content: "animation-face-in leading-tight",
                    }}
                  />
                }
                // value={changedEmail.length > 0 ? changedEmail : email} // disable change email
                value={email}
              />
            </div>
            {/* <Button
              handleOnClick={() => {
                setErrors({})
                setResponseStatus({})
                toggleChangeEmail(true)
              }}
              disabled={isProcessing}
              className="underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800"
            >
              <UpdateLabel
                label={getPrismicText(accountEmailField.update_button_label)}
              />
            </Button> */}
          </div>
        )}
        <div className="flex justify-between items-baseline">
          <InformationRow
            label={selectedSubscriptionField.name.text}
            value={selectedSubscriptionTitle}
            testId="selected-subscription"
          />
          {!isAffiliate && !signedLink && (
            <Button
              handleOnClick={() => toggleChangeSubscriptionType(true)}
              disabled={isProcessing}
              submitting={isProcessing}
              className="underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800 text-right"
            >
              <UpdateLabel
                label={selectedSubscriptionField.update_button_label.text}
              />
            </Button>
          )}
        </div>
        <InformationRow
          label={amountField.name.text}
          value={`${currencySymbol}${amountDue} ${
            isNotIncludedTax
              ? // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-1535
                // Hide (+ tax) if amount is 0
                amountDue > 0
                ? `(${plusTaxText})`
                : ""
              : `(${getIncTaxText(countryCode, layoutData)})`
          }`}
        />
        {/* Payment method info */}
        <div className="flex justify-between items-baseline">
          <InformationRow
            label={paymentMethodField.name.text}
            value={
              paymentMethod ? (
                <PaymentMethod paymentMethod={paymentMethod} />
              ) : null
            }
            testId="payment-method"
          />
          <Link
            className={`underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800${
              isProcessing ? " disabled" : ""
            }`}
            data-cy="update-payment-btn"
            to={getCreateAccountRoute(isAffiliate, lang) + "?step=payment"}
            onClick={handleDisableLink}
          >
            <UpdateLabel label={paymentMethodField.update_button_label.text} />
          </Link>
        </div>
        {/* Rollover preferences info */}
        {renderRolloverPreferences()}
        {renderPaymentNotes()}
        {renderTerms()}
      </InformationWrapper>
      {/* Recaptcha V2 */}
      {!recaptchaAutomation && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_SITE_KEY}
        />
      )}
      <Button
        disabled={isProcessing}
        submitting={isProcessing}
        className={`btn btn-primary py-3 w-full mt-20 ${
          isShowLoadingMessage ? "mb-20 md:mb-20" : "mb-35 md:mb-45"
        }`}
        handleOnClick={handleSubmit}
      >
        {isProcessing
          ? confirm_loading_button_label.text
          : getConfirmButtonLabel()}
      </Button>
      {/* Message during processing creating subscription */}
      {isShowLoadingMessage && (
        <div
          className={`text-base font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal text-3xs mb-35`}
        >
          <RichText
            render={creating_subscription_message.raw}
            linkResolver={linkResolver}
          />
        </div>
      )}
      {isOpenChangeSubscriptionType && (
        <SelectSubscriptionModal
          title={select_subscription_title.text}
          questions={questions}
          freeTrialNote={free_trial_note}
          handleDismiss={() =>
            toggleChangeSubscriptionType(!isOpenChangeSubscriptionType)
          }
          withTrialLabel={with_trial_label.text}
          prismicData={data}
          startNowBtn={start_now_button.text}
          faqTitle={faq_title.text}
          handleSelectSubscription={callbackAfterChangeSubscription}
          layoutData={layoutData}
          promotionData={promotionData}
        />
      )}
      {/* Change email function was disabled on Summary step */}
      {/* {isOpenChangeEmail && (
        <Popup
          title={getPrismicText(change_email_title)}
          btnRejectLabel={getPrismicText(change_email_cancel_button_label)}
          btnSubmitLabel={getPrismicText(change_email_save_button_label)}
          handleSubmit={() => handleChangeEmail()}
          handleDismiss={() => toggleChangeEmail(false)}
        >
          {Object.keys(responseStatus).length > 0 && (
            <NotificationToast
              children={<p>{responseStatus.message}</p>}
              showHideIcon
              handleHideToast={() => setResponseStatus({})}
              type={"error"}
              classNames={{
                wrapper: " mt-20",
              }}
            />
          )}
          <Textbox
            label={getPrismicText(email_label)}
            classNames={{
              wrapper: "mb-0 lg:mb-0 w-full mt-100 md:mt-0",
              textbox:
                "w-full h-textbox-base lg:h-textbox-lg lg:text-2lg mb-205 md:mb-45",
              label: "lg:text-2lg",
            }}
            inputRef={emailRef}
            handleOnKeyPress={e => handleOnKeyPress(e, handleChangeEmail)}
            id="change-email-summary"
            disabled={isProcessing}
            error={errors.email}
          />
        </Popup>
      )} */}
    </CreateAccountContainer>
  )
}

export default memo<Props>(Summary)
