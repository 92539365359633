// @flow
import React, { useState, memo } from "react"
import RolloverPreferences from "./RolloverPreferences"
import {
  Modal,
  NotificationToast,
  FormTitle,
  Button,
  isEmpty,
} from "@lesmills/gatsby-theme-common"

type PrismicData = {|
  rollover_preferences_title: Array<LabelType>,
  change_rollover_preferences_popup_title: Array<LabelType>,
  change_rollover_preferences_button_label: Array<LabelType>,
  rollover_preferences_continue_label: Array<LabelType>,
  rollover_preferences_rollback_label: Array<LabelType>,
|}

type Props = {|
  prismicData: PrismicData,
  layoutData: Object,
  currentSubscription: Object,
  selectedSubscription: Object,
  error: Object,
  onDismiss: () => void | Promise<void>,
  onResetError: () => void | Promise<void>,
  onSaveRolloverPreferences: () => void | Promise<void>,
|}

const RolloverPreferencesModal = ({
  prismicData = {},
  layoutData = {},
  currentSubscription = {},
  selectedSubscription = {},
  onDismiss = () => {},
  error = {},
  onResetError = () => {},
  onSaveRolloverPreferences = () => {},
}: Props) => {
  const {
    change_rollover_preferences_popup_title = {},
    change_rollover_preferences_button_label = {},
    rollover_preference_required = {},
  } = prismicData

  const [rolloverOption, setRolloverOption] = useState("")
  const [err, setErr] = useState("")

  const handleSubmit = () => {
    if (!rolloverOption) {
      setErr(rollover_preference_required.text)
    } else {
      onSaveRolloverPreferences(rolloverOption)
    }
  }

  return (
    <Modal
      handleDismiss={onDismiss}
      classNames={{
        content:
          " w-auto md:w-726 md:h-max-content overflow-auto md:py-0 md:px-0",
      }}
      testId="rollover-preferences-modal"
    >
      <div className="mx-auto md:w-533">
        <FormTitle
          title={change_rollover_preferences_popup_title.text}
          classNames="text-center lg:mt-25 mt-40 mb-20 leading-32 md:text-left"
        />
        {!isEmpty(error) && (
          <NotificationToast
            children={<p>{error.message}</p>}
            showHideIcon
            handleHideToast={onResetError}
            type="error"
            classNames={{
              wrapper: " mt-20",
            }}
          />
        )}
        <RolloverPreferences
          prismicData={prismicData}
          layoutData={layoutData}
          currentSubscription={currentSubscription}
          selectedSubscription={selectedSubscription}
          onChange={value => {
            setErr("")
            setRolloverOption(value)
          }}
          rolloverOption={rolloverOption}
          error={err}
          classNames={{
            wrapper:
              "border-gray-35 border-b pt-32 pb-32 mb-26 text-center md:text-left",
            buttonWrapper: " mt-25 mb-15",
            message:
              " text-center md:text-left text-base md:leading-22 leading-20",
            button: " leading-22 text-base",
            question: " leading-22 text-base",
          }}
        />
        <div className="flex lg:flex-row flex-col justify-center lg:mt-10 mt-40 mb-20">
          <Button
            handleOnClick={handleSubmit}
            className="btn btn-primary w-full mb-0"
            testId="save-rollover-preferences-btn"
          >
            {change_rollover_preferences_button_label.text}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default memo<Props>(RolloverPreferencesModal)
