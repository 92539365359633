// @flow
import React, { memo } from "react"
import {
  pluralize,
  Button,
  getSubscriptionType,
  Notification,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  layoutData: Object,
  currentSubscription: Object,
  selectedSubscription: Object,
  onChange: () => void | Promise<void>,
  rolloverOption?: Boolean | String,
  error?: String,
  classNames?: Object,
  disabled?: Boolean,
|}

export const formalizeContinueLabel = (
  label = {},
  name = "",
  product_price_point = {},
  layoutData = {}
) => {
  // Original text: Continue @current_subscription after @interval
  if (label.length > 0) {
    return [
      {
        ...label[0],
        text: label[0].text
          .replace("@current_subscription", name)
          .replace(
            "@interval",
            formalizeIntervalUnit(product_price_point, layoutData)
          ),
      },
    ]
  }
  return label
}

export const formalizeRollbackLabel = (
  label = {},
  product_price_point = {},
  layoutData = {}
) => {
  // Original text: Rollback to Monthly subscription after @interval
  if (label.length > 0) {
    return [
      {
        ...label[0],
        text: label[0].text.replace(
          "@current-sub",
          formalizeIntervalUnit(product_price_point, layoutData)
        ),
      },
    ]
  }
  return label
}

export const formalizeIntervalUnit = (
  product_price_point = {},
  layoutData = {}
) => {
  const { interval = "", interval_unit = "" } = product_price_point

  return `${interval}${interval ? " " : ""}${pluralize(
    interval,
    interval_unit,
    !interval_unit ? "" : layoutData[`${interval_unit}_plural`].text
  )}`
}

export const formalizeRolloverLabel = (
  label = {},
  currentSubscription = {},
  selectedSubscription = {}
) => {
  // Original text: Rollback to Monthly subscription after @interval
  if (label.raw && label.raw.length > 0) {
    return {
      text: replaceSubscriptionName(
        label.text,
        currentSubscription.name,
        selectedSubscription.name
      ),
      raw: [
        {
          ...label.raw[0],
          text: replaceSubscriptionName(
            label.text,
            currentSubscription.name,
            selectedSubscription.name
          ),
        },
      ],
    }
  }
  return label
}

export const replaceSubscriptionName = (
  text = "",
  currentName = "",
  newName = ""
) => {
  return text
    .replace(/@current_subscription/g, currentName)
    .replace(/@new_subscription/g, newName)
}

export const ROLLOVER_OPTION = {
  continue: "false",
  rollback: "true",
}

const RolloverPreferences = ({
  layoutData = {},
  currentSubscription = {},
  onChange = () => {},
  rolloverOption = "",

  error = "",
  classNames = {
    wrapper: "",
    buttonWrapper: "",
    message: "",
    button: "",
    question: "",
  },
  disabled = false,
}: Props) => {
  const {
    rollover_preference_question = { text: "" },
    rollover_preference_rollback_message = {},
    rollover_preference_continue_message = {},
  } = layoutData
  const currentSubscriptionName = getSubscriptionType(
    currentSubscription.product_price_point,
    layoutData
  )
  const selectedBtnStyle = selected =>
    selected ? "bg-orange-400 font-base-black" : "font-base-light bg-white"

  return (
    <div className={classNames.wrapper}>
      <p className={"text-gray-800 font-base-black" + `${classNames.question}`}>
        {replaceSubscriptionName(
          rollover_preference_question.text,
          currentSubscriptionName.toLowerCase()
        )}
      </p>
      <div className={"flex" + classNames.buttonWrapper}>
        <Button
          className={
            `${error ? "border-red-700" : "border-gray-500"}` +
            `${classNames.button}` +
            " rounded-l-3 pt-10 pb-10 w-1/2 border text-gray-800 border-r-0 text-center " +
            selectedBtnStyle(rolloverOption === ROLLOVER_OPTION.continue)
          }
          handleOnClick={() => onChange(ROLLOVER_OPTION.continue)}
          disabled={disabled}
        >
          {getSubscriptionType(
            currentSubscription.product_price_point,
            layoutData,
            true
          )}
        </Button>
        <Button
          className={
            `${error ? "border-red-700" : "border-gray-500"}` +
            `${classNames.button}` +
            " rounded-r-3 pt-10 pb-10 w-1/2 border text-gray-800 text-center " +
            selectedBtnStyle(rolloverOption === ROLLOVER_OPTION.rollback)
          }
          handleOnClick={() => onChange(ROLLOVER_OPTION.rollback)}
          disabled={disabled}
        >
          {layoutData.monthly_interval.text}
        </Button>
      </div>
      {error ? <Notification type="error" message={error} /> : null}
      {rolloverOption ? (
        <p
          className={`font-base-light leading-20 md:text-base text-3xs text-gray-800${classNames.message ||
            ""}`}
        >
          {rolloverOption === ROLLOVER_OPTION.rollback
            ? rollover_preference_rollback_message.text
            : replaceSubscriptionName(
                rollover_preference_continue_message.text,
                currentSubscriptionName
              )}
        </p>
      ) : null}
    </div>
  )
}

export default memo<Props>(RolloverPreferences)
