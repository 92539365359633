export const newSubscriptionWithPromotion = `
  mutation newSubscriptionWithPromotion(
    $product_handle: String!
    $default_to_monthly: Boolean
    $offer_id: String
    $affiliate_id: String
    $transaction_id: String
    $promotion_signed_link_id: String
    $captcha_signature: CaptchaSignatureInput
    $recaptcha_token: String!
    $recaptcha_token_V3: String
  ) {
    newSubscriptionWithPromotion(
      captcha_signature: $captcha_signature
      recaptcha_token: $recaptcha_token
      recaptcha_token_V3: $recaptcha_token_V3
      product_handle: $product_handle
      default_to_monthly: $default_to_monthly
      offer_id: $offer_id
      affiliate_id: $affiliate_id
      transaction_id: $transaction_id
      promotion_signed_link_id: $promotion_signed_link_id
    ) {
      user_id
      product {
        product_handle
        name
        description
      }
    }
  }
`
